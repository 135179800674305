import * as React from 'react';
import styled from '@emotion/styled';
import GatsbyImage from 'gatsby-image';

interface Props {
  title: string;
  subtitle: string;
  description: string;
  preview: any;
}

export function Poster(props: Props) {
  return (
    <Block>
      <Stage>
        <GatsbyImage fluid={props.preview} />
      </Stage>
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>

      <SeeMore>
        <svg width={11} height={11}>
          <g
            transform="translate(1 1)"
            stroke="currentColor"
            fill="none"
            fillRule="evenodd"
          >
            <circle fill="currentColor" cx={3} cy={4} r={2} />
            <circle cx={4.5} cy={4.5} r={4.5} />
          </g>
        </svg>
        <svg width={11} height={11}>
          <g
            transform="translate(1 1)"
            stroke="currentColor"
            fill="none"
            fillRule="evenodd"
          >
            <circle fill="currentColor" cx={5} cy={6} r={2} />
            <circle cx={4.5} cy={4.5} r={4.5} />
          </g>
        </svg>
        {' '}
        see more
      </SeeMore>
    </Block>
  );
}

const Stage = styled.div`
  position: relative;
  padding-right: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
  box-sizing: border-box;

  > * {
    transition: transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  ::before {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 0;
    bottom: 0;
    background: hsla(177, 68%, 77%, 0.5);
    content: '';
    transition: background 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  img {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    margin: 0;
  }
`;

const Block = styled.div`
  position: relative;
  width: 50%;
  float: left;
  color: #3c434c;
  margin: 0 0 3rem 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  box-sizing: border-box;

  :hover {
    ${Stage} > * {
      transform: translate(0.3rem, 0.3rem);
    }

    ${Stage}::before {
      background: hsla(177, 68%, 60%, 0.5);
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

const Title = styled.h2`
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 1rem;
  margin-bottom: 0;
  text-transform: uppercase;
`;

const Description = styled.p`
  font-size: 0.7rem;
  line-height: 1rem;
  margin-bottom: 0;
`;

const SeeMore = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 0.7rem;
`;
